// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/usePrefixedTranslation.ts"
);
import.meta.hot.lastModified = "1736899405882.1182";
}
// REMIX HMR END

import { KeyPrefix, Namespace } from 'i18next'
import { useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next'

/**
 * Utility accessor for `useTranslation` with a key prefix.
 *
 * @param keyPrefix - Key from the default export ../locales/[lng]/index.ts
 */
export function usePrefixedTranslation<N extends Namespace>(
  keyPrefix: KeyPrefix<N>,
  options: Omit<UseTranslationOptions<N>, 'keyPrefix'> = {}
): UseTranslationResponse<N, KeyPrefix<N>> {
  return useTranslation(undefined, { keyPrefix, ...options })
}
